<template>
  <v-dialog v-model="isShow" max-width="420px" class="dialog-confirm">
    <v-card>
      <v-card-title class="headline" v-html="message"></v-card-title>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="cancel">{{
          $t("common.cancel")
        }}</v-btn>
        <v-btn class="primary pl-10 pr-10 ml-10" text @click="agree">{{
          $t("common.confirm")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  props: {
    message: {
      type: String,
      default: i18n.t("common.confirmMessage"),
    },
  },
  data() {
    return {
      isShow: false,
      resolve: null,
    };
  },
  methods: {
    confirm() {
      this.isShow = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    agree() {
      this.resolve(true);
      this.isShow = false;
    },
    cancel() {
      this.resolve(false);
      this.isShow = false;
    },
  },
};
</script>